:root {
  --trans-linear: 0.3s linear;
  --main-color: #1a3260;
  --seconde-color: #4590b8;
  --third-color: #969fa7;
  --borderRadius: 0.5rem;
}
*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
  margin: 0 !important;
  padding-left: 0 !important;
}
a {
  text-decoration: none !important;
}
h1 {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  margin-bottom: 12px;
  margin: 0 0 2rem 0 !important;
  transition: var(--trans-linear);
}
.heading-section {
  font-size: 2rem;
  line-height: 44px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 2rem;
  text-transform: capitalize;
}
@media (max-width: 768px) {
  .heading-section {
    font-size: 1.5rem;
  }
}
html {
  scroll-behavior: smooth;
}
.pd-section {
  padding: 5rem 0;
}

header {
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3));
  overflow: hidden;
  position: relative;
}
.imgs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  transition: all 0.25s ease-in-out;
}
nav {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 111;
  padding: 1rem 1.5rem;
}
nav.fixed-nav {
  background-color: white;
  box-shadow: 0 10px 24px #445b6f1a;
}
.btn-scroll {
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  position: fixed;
  bottom: 45px;
  right: -205px;
  background-color: var(--seconde-color);
  color: white;
  width: 35px;
  height: 35px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 22px;
  transition: var(--trans-linear);
  z-index: 2;
}
.up-btn {
  right: 35px;
}
.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-header img {
  width: 100px;
}
.nav-toggle {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  transition: var(--trans-linear);
}
.nav-toggle:hover {
  transform: rotate(90deg);
  color: var(--seconde-color);
}
.nav-links {
  height: 0;
  overflow: hidden;
  display: block;
  transition: var(--trans-linear);
}
.service-link {
  background: none;
  border: none;
}
.links a.active,
.links .service-link.active {
  color: var(--seconde-color);
}
.links a,
.links .service-link {
  display: block;
  text-transform: capitalize;
  font-size: 1.1rem;
  font-weight: bold;
  transition: var(--trans-linear);
}
.meti-logo-white {
  display: none;
}
.meti-logo-black {
  display: block;
}
.fixed-nav .meti-logo-white {
  display: none;
}
.fixed-nav .meti-logo-black {
  display: block;
}
.fixed-nav .links a,
.fixed-nav .links .service-link {
  color: black;
}
.fixed-nav .links a.active,
.fixed-nav .links .service-link.active {
  color: var(--seconde-color);
}
.links a:hover,
.links .service-link:hover {
  color: black;
}
.text {
  height: 100vh;
  display: grid;
  place-content: center;
  text-align: center;
  color: white;
}
.content {
  width: 80vw;
  max-width: 805px;
}
.text h1.about-us {
  font-size: 2rem;
}
.text h1.home {
  font-size: 2rem;
}
.text h3 {
  line-height: 1.7;
  font-size: 1rem;
}
.change-background {
  position: absolute;
  top: 50%;
  color: white;
  opacity: 0.7;
  clear: rgba(100%, 100%, 100%, 50%);
  cursor: pointer;
  transition: var(--trans-linear);
}
.change-background:hover {
  opacity: 1;
}
@media (min-width: 547px) {
  .text h1.about-us {
    font-size: 3rem;
  }
}
@media (min-width: 769px) {
  .meti-logo-white {
    display: block;
  }
  .meti-logo-black {
    display: none;
  }
  .nav-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nav-toggle {
    display: none;
  }
  .links {
    display: flex;
  }
  .links a,
  .links .service-link {
    color: white;
    margin: 0 1rem;
    padding: 0;
  }
  .links a:hover,
  .links .service-link:hover {
    color: var(--seconde-color);
  }
  .nav-links {
    height: auto !important;
  }
  .text h1.about-us {
    font-size: 4.5rem;
  }
  .text h1.home {
    font-size: 3rem;
  }
  .text h3 {
    font-size: 1.5rem;
  }
}

@media (max-width: 547px) {
  nav {
    background-color: white;
  }
}
@media (max-width: 768px) {
  nav {
    background-color: white;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 10%);
  }
  .nav-header a {
    color: black;
  }
  .nav-header a:hover {
    color: var(--seconde-color);
  }
}

.numbers-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  column-gap: 50px;
}
.numbers-box {
  font-size: 4.8rem;
}
.numbers-box p {
  font-size: 1rem;
  text-transform: uppercase;
  margin-top: 1rem;
}
.nums1,
.nums2 {
  position: relative;
}
.nums1::after,
.nums2::after {
  content: "";
  position: absolute;
  width: 215px;
  bottom: -40px;
  height: 8px;
  background: var(--main-color);
  background: -webkit-linear-gradient(
    linear,
    left top,
    right top,
    from(var(--main-color)),
    color-stop(24%, var(--main-color)),
    color-stop(79%, var(--seconde-color)),
    to(white)
  );
  background: -webkit-linear-gradient(
    left,
    var(--main-color) 0,
    var(--main-color) 24%,
    var(--seconde-color) 79%,
    #fff 100%
  );
  background: -moz-linear-gradient(
    left,
    var(--main-color) 0,
    var(--main-color) 24%,
    var(--seconde-color) 79%,
    #fff 100%
  );
  background: -o-linear-gradient(
    left,
    var(--main-color) 0,
    var(--main-color) 24%,
    var(--seconde-color) 79%,
    #fff 100%
  );
  background: linear-gradient(
    90deg,
    var(--main-color) 0,
    var(--main-color) 24%,
    var(--seconde-color) 79%,
    #fff 100%
  );
  margin-bottom: 30px;
}
.nums1::after {
  left: 0;
}
.nums2::after {
  left: -40px;
}
.gradient-line {
  position: relative;
  display: block;
  width: 0;
}

.clinets-container {
  width: 90vw;
  margin: 0 auto;
}
.clients .swiper {
  width: 100%;
  height: 100%;
  overflow: visible !important;
}

.clients .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-wrapper {
  align-items: center;
}
.clients .swiper-slide img {
  display: block;
  height: 100%;
  object-fit: cover;
}
.clients .img-19 {
  width: 190px;
}
.clients .img-18 {
  width: 180px;
}
.clients .img-13 {
  width: 130px;
}
.clients .img-12 {
  width: 120px;
}
.clients .img-10 {
  width: 100px;
}
.clients .img-9 {
  width: 90px;
}
.clients .swiper-pagination {
  bottom: -30px !important;
}
@media (max-width: 540px) {
  .clients .swiper-button-prev::after,
  .clients .swiper-button-next::after {
    font-size: 30px;
  }
  .clients .img-19,
  .clients .img-18,
  .clients .img-13,
  .clients .img-12 {
    width: 100px;
  }
  .clients .img-10,
  .clients .img-9 {
    width: 70px;
  }
}

.submenu {
  display: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
@media screen and (min-width: 768px) {
  .submenu {
    display: block;
    position: fixed;
    left: 50%;
    background: white;
    padding: 2rem;
    transform: rotateY(90deg) translate(-50%);
    transform-origin: left;
    perspective: 1000px;
    border-radius: var(--borderRadius);
    visibility: hidden;
    opacity: 0;
    transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;
    z-index: -1;
  }
  .show-submenu {
    visibility: visible;
    opacity: 1;
    transform: rotateX(0) translate(-50%);
    z-index: 10;
  }
  .sidebar {
    display: none;
  }
}
.submenu::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}
.col-2 {
  grid-template-columns: repeat(2, 1fr);
}
.col-3 {
  grid-template-columns: repeat(3, 1fr);
}
.col-4 {
  grid-template-columns: repeat(4, 1fr);
}
.submenu h4 {
  margin-bottom: 1.5rem;
}
.submenu-links {
  display: grid;
  gap: 2rem;
  margin-top: 1rem;
}
.submenu-links a {
  width: 10rem;
  display: block;
  color: hsl(209, 61%, 16%);
  text-transform: capitalize;
  display: flex;
  align-items: center;
  transition: var(--trans-linear);
  font-size: 1.1rem;
}
.submenu-links a:hover {
  color: var(--seconde-color);
}
.submenu-links svg {
  color: hsl(210, 22%, 49%);
  margin-right: 1rem;
}

.sidebar {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 600px;
  border-radius: var(--borderRadius);
  background: white;
  opacity: 0;
  visibility: hidden;
  transition: var(--trans-linear);
  box-shadow: 0px 5px 15px rgb(0 0 0 / 10%);
  z-index: 10;
}
.show-sidebar {
  opacity: 1;
  visibility: visible;
  z-index: 1000;
}
.sidebar-container {
  padding: 4rem 2rem;
  position: relative;
}
.close-btn {
  font-size: 2rem;
  background: transparent;
  border-color: transparent;
  color: red;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}
.sidebar article {
  border-bottom: 1px solid;
}
.sidebar h4 {
  margin-bottom: 1rem;
  color: black;
  font-size: 1.563rem;
}
.sidebar-sublinks {
  display: flex;
  gap: 1rem 1.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  flex-wrap: wrap;
}
@media (min-width: 400px) {
  .sidebar-sublinks {
    justify-content: space-evenly;
  }
}
.sidebar-sublinks a {
  display: block;
  color: black;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.sidebar-link {
  display: block;
  color: black;
  font-size: 1.2rem;
  border-bottom: 1px solid black;
  width: 100%;
  text-align: start;
  padding: 1rem 0;
  font-weight: bold;
  transition: var(--trans-linear);
}
.sidebar-link:hover,
.sidebar-sublinks a:hover {
  color: var(--seconde-color);
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

.service {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background: linear-gradient(
    0deg,
    rgba(240, 101, 36, 0.6727065826330532) 0%,
    rgba(255, 100, 100, 1) 100%
  );
}
.wrapper .tables {
  background: #fff;
  padding: 2rem;
  position: relative;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}
@media (max-width: 540px) {
  .wrapper .tables:not(:last-child) {
    margin-bottom: 2rem;
  }
}
@media (min-width: 540px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 1rem;
  }
}
.tables .price-section {
  display: flex;
  justify-content: center;
}
.tables .price-area {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  padding: 2px;
}

.aj_p {
  text-align: center;
}

p.aj_des {
  color: grey;
  padding-bottom: 30px;
  font-size: 14px;
}

.premium_all {
  padding-top: 22px;
}

.price-area .inner-area {
  height: 100%;
  width: 100%;
  line-height: 117px;
  text-align: center;
  color: #fff;
  position: relative;
}

.price-area .inner-area .text {
  font-size: 25px;
  font-weight: 400;
  position: absolute;
  top: -10px;
  left: 5px;
}

.price-area .inner-area .price {
  font-size: 45px;
  font-weight: 500;
  margin-left: 16px;
}

.tables .package-name {
  width: 100%;
  height: 2px;
  margin: 35px 0;
  position: relative;
}

.tables .features li {
  margin-bottom: 15px;
  list-style: none;
  display: flex;
  align-items: center;
}
.features {
  padding: 0;
  transition: 0.5s;
  overflow: hidden;
}
.features li .list-name {
  font-size: 17px;
  font-weight: 400;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.show-features {
  cursor: pointer;
  margin: 1rem 0 2rem;
}
.show-featuer {
  height: 245px;
}
.hidden-featuer {
  height: 400px;
}
.features li .icon {
  font-size: 15px;
}

.features li .icon.check {
  margin-right: 0.5rem;
  color: #2db94d;
}

.features li .icon.cross {
  margin-right: 0.5rem;
  color: #cd3241;
}
.ultimate .btn button {
  background: #f5b55a;
  color: #fff;
  margin-top: -75px;
}

.ultimate .btn button:hover {
  background: rgba(240, 101, 36);
  color: #fff;
}

.learn-more-link {
  display: block;
  width: fit-content;
}
button.learn-more {
  display: flex;
  padding: 0.5rem 1rem;
  background: var(--main-color);
  border-radius: 10px;
  border: 1px solid #03045e;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-in;
  z-index: 1;
}

button.learn-more::before,
button.learn-more::after {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  height: 100%;
  transform: skew(15deg);
  transition: all 0.5s;
  overflow: hidden;
  z-index: -1;
}

button.learn-more::before {
  left: -10px;
  background: var(--main-color);
}

button.learn-more::after {
  right: -10px;
  background: var(--seconde-color);
}

button.learn-more:hover::before,
button.learn-more:hover::after {
  width: 58%;
}

button.learn-more:hover span {
  color: white;
  transition: 0.3s;
}

button.learn-more span {
  color: white;
  font-size: 18px;
  transition: all 0.3s ease-in;
}
@media (max-width: 756px) {
  .wrapper .tables {
    width: 100%;
  }

  .tables.premium {
    margin: 40px 0px;
  }
  .tables.ultimate {
    margin: 0px;
  }
}

.tables .ribbon {
  width: 150px;
  height: 150px;
  position: absolute;
  top: -10px;
  left: -10px;
  overflow: hidden;
}

.tables .ribbon::before,
.tables .ribbon::after {
  position: absolute;
  content: "";
  z-index: -1;
  display: block;
  border: 7px solid #ba24f0;
  border-top-color: transparent;
  border-left-color: transparent;
}

.tables .ribbon::before {
  top: 0px;
  right: 15px;
}

.tables .ribbon::after {
  bottom: 15px;
  left: 0px;
}

.tables .ribbon span {
  position: absolute;
  top: 30px;
  right: 0;
  transform: rotate(-45deg);
  width: 200px;
  background: #ba24f0;
  padding: 10px 0;
  color: #fff;
  text-align: center;
  font-size: 17px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
}
.grid-services {
  gap: 2rem 1rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.grid-services img {
  width: 100%;
  border-radius: 0.5rem 0.5rem 0 0;
  height: 250px;
  object-fit: cover;
}
.box {
  background: white;
  box-shadow: 0 10px 24px #445b6f1a;
  transition: var(--trans-linear);
  border-radius: var(--borderRadius);
}
.box:hover {
  box-shadow: 0 20px 20px 5px #445b6f1a;
  transform: translateY(-10px);
}
.box .contents {
  padding: 1rem;
}
.box .contents h5 {
  letter-spacing: -1px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .box {
    flex-basis: calc(50% - 1rem);
  }
}
@media (min-width: 1200px) {
  .box {
    flex-basis: calc((100% / 3) - 1rem);
  }
}
@media (min-width: 540px) {
  .grid-ai {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
}
.ai-solutions {
  padding: 2.5rem;
  background: var(--main-color);
  color: white;
}
.header-ai p {
  max-width: 800px;
  margin: -1rem auto 2rem;
  text-align: center;
  line-height: 2;
}
.ai-solutions .swiper-slide {
  background: none;
  text-align: center;
  align-items: center;
}
.ai-left {
  overflow: hidden;
}
.ai-left .swiper {
  overflow: visible !important;
  margin: 5rem 0;
}
.ai-left .swiper-pagination-bullet-active,
.ai-solutions .swiper-pagination-bullet {
  background: white;
}
.swiper-pagination {
  bottom: -45px !important;
}
.ai-left .swiper-button-prev,
.ai-left .swiper-button-next {
  color: white !important;
}
.ai-left .swiper-button-prev {
  left: var(--swiper-navigation-sides-offset, 0px);
}
.ai-left .swiper-button-next {
  right: var(--swiper-navigation-sides-offset, 0px);
}
.ai-left .content-swiper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.ai-left .content-swiper .icon-ai {
  width: 30px;
  margin-right: 0.5rem;
  border-radius: 0.25rem;
}
.ai-left .content-swiper button {
  color: white;
  background: none;
  border: none;
  font-size: 1.1rem;
}
@media (max-width: 768px) {
  .ai-left .content-swiper button {
    display: none;
  }
}
.ai-left p {
  max-width: 600px;
  line-height: 2;
}
.ai-right {
  text-align: center;
}
.ai-right img {
  object-fit: cover;
  width: 75vw;
}
.ai-solutions article {
  background: white;
  padding: 0.5rem 1rem;
  border-radius: var(--borderRadius);
}

.industries-container {
  background: var(--main-color);
  padding: 5rem 2.5rem !important;
  color: white;
  border-radius: var(--borderRadius);
}
.industries-container:not(:last-child) {
  margin-bottom: 4rem;
}
.left-container {
  overflow-x: auto;
}
.left-container::-webkit-scrollbar {
  width: 0px;
}
.left-container h3 {
  transition: var(--trans-linear);
  font-size: 2rem;
}
.scroll-buttons {
  overflow-x: auto;
}
.scroll-buttons::-webkit-scrollbar {
  width: 0px;
}
.industries-features {
  border: 1px solid hsl(0deg 0% 100% / 71%);
  border-radius: 6.25rem;
  padding: 0.25rem;
  min-width: max-content;
  max-width: max-content;
  font-size: 14px;
  line-height: 22px;
  margin: 2rem 0;
  display: flex;
}
.industries-features button {
  background: none;
  border: none;
  color: white;
  height: 30px;
  padding: 0 1rem;
  transition: var(--trans-linear);
  cursor: pointer;
}
.industries-features button.active {
  background-color: #eee;
  color: black;
  border-radius: 6.5rem;
}

.left-container figure {
  display: none;
}
.left-container img {
  width: 100%;
  transition: var(--trans-linear);
  margin-top: 2rem;
  border-radius: var(--borderRadius);
}
.right-container {
  position: relative;
  display: none;
}

.right-container figure {
  transition: var(--trans-linear);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.right-container img {
  width: 100%;
  border-radius: var(--borderRadius);
}
.right-container figure.right {
  right: -50px;
}
.right-container figure.left {
  left: -50px;
}
.industries-container figcaption {
  text-align: center;
  line-height: 1.8;
}
.right-container figure.left figcaption {
  margin-left: 1rem;
}
@media (min-width: 1045px) {
  .industries-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  .right-container {
    display: block;
  }
}
@media (min-width: 1200px) {
  .industries-container {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 1045px) {
  .left-container figure {
    display: block;
  }
}
@media (max-width: 540px) {
  .left-container h3 {
    font-size: 1.5rem;
  }
}

.footer {
  padding-top: 3.75rem;
  padding-bottom: 2.5rem;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #000f1b;
  background-image: (
    linear,
    left top,
    left bottom,
    color-stop(29%, #00243f),
    to(#32325d)
  );
  background-image: linear-gradient(180deg, #00243f 29%, #32325d);
  text-align: center;
}
@media screen and (max-width: 479px) {
  .footer {
    padding-right: 20px;
    padding-left: 20px;
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    padding: 40px 20px;
  }
}
@media screen and (max-width: 991px) {
  .footer {
    padding-right: 20px;
    padding-left: 20px;
  }
}
.w-container {
  margin-left: auto;
  margin-right: auto;
  width: 85vw;
}
.footer-flex-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-evenly;
  -ms-flex-pack: justify;
  justify-content: space-evenly;
  text-align: left;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .footer-flex-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: start;
  }
}
.footer-logo-link {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}
@media screen and (max-width: 768px) {
  .footer-logo-link {
    height: 60px;
  }
}
@media screen and (max-width: 768px) {
  .footer-logo-link {
    margin-right: 22px;
  }
}
.w-inline-block {
  max-width: 100%;
  display: inline-block;
}
.footer-image {
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 0% 50%;
  object-position: 0% 50%;
}
.div-block-17 {
  color: #fff;
}
.footer-heading {
  margin-top: 0px;
  margin-bottom: 20px;
  font-size: 1.2rem;
  line-height: 1.5;
}
@media screen and (max-width: 768px) {
  .footer-heading {
    margin-top: 20px;
  }
}
.w-list-unstyled {
  padding-left: 0;
  list-style: none;
}
.footer-link {
  display: block;
  margin-bottom: 10px;
  color: hsla(0, 0%, 100%, 0.7);
  text-decoration: none;
}
.div-block-18 {
  color: #fff;
}
.text-block-3 {
  color: #fff;
}

.contact-form-2 {
  position: relative;
  background-color: #01243f;
}
.container-4 {
  position: relative;
  z-index: 1;
  color: white;
}
.div-block-21 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  column-gap: 2rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.heading-5 {
  color: #fff;
  font-size: 2rem;
  line-height: 1.5;
  font-weight: bold;
  text-shadow: 1px 1px 50px hsla(0, 0%, 100%, 0.37);
  max-width: 600px;
}
.small-text {
  padding-top: 10px;
  opacity: 0.63;
  color: #fff;
  font-size: 25px;
  line-height: 1.1em;
  text-align: left;
}
.lottie-animation-6 {
  width: 220px;
  height: 210px;
}
.form-block {
  flex-basis: 40%;
  padding: 22px 24px;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 5px;
}

.w-form {
  margin: 0 0 15px;
}
.lottie-animation-6-svg {
  width: 100%;
  height: 100%;
  transform: translate3d(0px, 0px, 0px);
}
.contact-form-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
.div-block-24 {
  padding-bottom: 10px;
}

.div-block-25 {
  padding-bottom: 10px;
}

.div-block-26 {
  padding-bottom: 0px;
}

.div-block-27 {
  padding-bottom: 10px;
}
label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}
.text-field {
  border-radius: 3px;
  opacity: 0.45;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}
.text-field:hover {
  opacity: 1;
}
.w-input,
.w-select {
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

.submit-button-2.animgradient {
  width: 100%;
  margin-top: 28px;
  -webkit-transition: opacity 200ms ease,
    -webkit-transform 200ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: opacity 200ms ease,
    -webkit-transform 200ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: opacity 200ms ease,
    transform 200ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: opacity 200ms ease,
    transform 200ms cubic-bezier(0.68, -0.55, 0.265, 1.55),
    -webkit-transform 200ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
input.w-button {
  -webkit-appearance: button;
}
.animgradient {
  background: linear-gradient(-45deg, #004479, #e73c7e, #004479, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}
.submit-button-2 {
  padding: 15px 44px;
  border-radius: 3px;
  background-color: #004479;
  font-size: 18px;
}
.w-button {
  display: inline-block;
  padding: 9px 15px;
  background-color: #3898ec;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
}
.success-message {
  padding-right: 43px;
  padding-left: 43px;
  border-radius: 20px;
  background-color: #fff;
}

.w-form-done {
  display: none;
  padding: 20px;
  text-align: center;
  background-color: #dddddd;
}
.w-form-fail {
  display: none;
  margin-top: 10px;
  padding: 10px;
  background-color: #ffdede;
}
.div-block-23 {
  flex-basis: 60%;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@media screen and (max-width: 991px) {
  .div-block-21 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .form-block {
    width: 70%;
    margin-top: 3rem;
  }
  .div-block-23 {
    text-align: center;
  }
  .heading-5 {
    margin: 0 auto;
  }
  .small-text {
    text-align: center;
  }
  .div-block-29 {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }
  .lottie-animation-6 {
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (max-width: 767px) {
  .contact-form-2 {
    padding: 40px 20px;
  }
  .heading-5 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 479px) {
  .div-block-23 {
    margin-right: 0px;
  }
  .heading-5 {
    font-size: 28px;
    line-height: 30px;
  }
  .small-text {
    font-size: 18px;
    font-weight: 500;
  }
  .form-block {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    border-color: hsla(0, 0%, 100%, 0.23);
  }
  .submit-button-2.animgradient {
    width: 100%;
  }
}