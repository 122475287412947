.not-found {
  background-image: linear-gradient(180deg, #00243f 29%, #32325d);
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.not-found h2 {
  font-size: 4rem;
}
.not-found p {
  font-size: 2rem;
}
.not-found .home-link {
  color: white;
  margin-left: 2rem;
  text-decoration: underline !important;
  transition: var(--trans-linear);
}
.not-found .home-link:hover {
  color: var(--seconde-color);
}
