.about-container {
  width: 85vw;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .about-container {
    width: 65vw;
  }
}
.about-container h2 {
  text-align: center;
  white-space: pre-wrap;
  margin-bottom: 2rem;
}
.about-container p {
  line-height: 1.8;
  margin: 1.5rem 0;
  font-size: 1.2rem;
}
.Our-Expertise h2 {
  text-align: center;
  white-space: pre-wrap;
}
.expertise {
  display: none;
}
.Our-Expertise p {
  display: none;
}
@media (max-width: 768px) {
  .Our-Expertise {
    width: 85vw;
    margin: 0 auto;
  }
  .Our-Expertise p {
    display: block;
    line-height: 1.8;
    margin: 1.5rem 0;
    font-size: 1.2rem;
  }
}
@media (min-width: 768px) {
  .Our-Expertise .expertise {
    display: block;
    width: 85vw;
    margin: 0 auto;
  }
  .Our-Expertise .expertise img {
    width: 100%;
  }
}
